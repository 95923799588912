@font-face {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Regular'), url('/assets/fonts/OpenSans-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans'), url('/assets/fonts/OpenSans-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Italic'), url('/assets/fonts/OpenSans-Italic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Light'), url('/assets/fonts/OpenSans-Light.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Light Italic'), url('/assets/fonts/OpenSans-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans SemiBold'), url('/assets/fonts/OpenSans-SemiBold.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans SemiBold Italic'), url('/assets/fonts/OpenSans-SemiBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Bold'), url('/assets/fonts/OpenSans-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Bold Italic'), url('/assets/fonts/OpenSans-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans ExtraBold'), url('/assets/fonts/OpenSans-ExtraBold.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans ExtraBold Italic'), url('/assets/fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Light Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Light Regular'), url('/assets/fonts/OpenSans-Light-webfont.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Condensed Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Condensed Bold'), url('/assets/fonts/OpenSans-CondBold.woff') format('woff');
}
