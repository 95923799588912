@charset "UTF-8";
html {
  font-family: 'Open Sans', sans-serif !important;
  line-height: 1.58;
  -ms-overflow-style: scrollbar;
  -webkit-text-size-adjust: 100%;
}

body, .p-component {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
}

/*LAYOUT*/
.layout-content {
  background-color: #ffffff;
}

.layout-content:not(.nologin):not(.no-menu-no-header) {
  padding-top: 75px;
  padding-left: 260px;
}

.layout-content.login.hidden-menu-content {
  padding-left: 0 !important;
}

.layout-content.nologin {
  padding-left: 0;
}

/* -->> */

/*LAYOUT MENUBAR*/
.layout-menubar {
  background-color: #F1F3F5;
  height: calc(100vh - 75px);
  top: 75px;
  overflow: auto;
  position: fixed;
  width: 260px;
  z-index: 1000;
}

/* -->> */

/*LAYOUT APP MENU*/
.layout-appmenu {
  padding: 5px;
  margin: 0;
}

.layout-appmenu ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.layout-appmenu-sub ul {
  width: 94%;
  padding-left: 20px !important;
}

.layout-appmenu li a {
  padding: 7px;
  display: block;
  text-decoration: none !important;
  position: relative;
  color: inherit;
}

.layout-appmenu li a.p-overlay-badge .p-badge {
  top: 10px;
  right: 10px;
}

.layout-appmenu li a.menu-node {
  text-transform: uppercase;
}

.layout-appmenu li a .layout-menuitem-icon {
  color: #a7a7a7;
  opacity: 0.5;
}

.layout-appmenu a.appmenu-box {
  border-top: 1px solid #e3e9ea !important;
  border-bottom: 1px solid #e3e9ea !important;
  box-sizing: border-box;
}

.layout-appmenu-sub a.appmenu-box-sub {
  border-top: 1px solid #f6f8f9 !important;
  border-bottom: 1px solid #f6f8f9 !important;
  box-sizing: border-box;
}

.layout-appmenu li a:hover, .layour-appmenu .active {
  cursor: pointer;
}

.layout-appmenu li a.active {
  background-color: #D2D7DE;
}

.layout-appmenu .fa {
  font-size: 20px;
  text-align: center;
}

.layout-appmenu .appmenu-box-sub .fa {
  font-size: 16px;
  color: #c0c0c0;
}

.layout-appmenu li ul {
  display: block;
}

.layout-appmenu li ul::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 0;
  bottom: 0;
}

.layout-appmenu .menu-badge > .p-badge {
  background-color: #E35855 !important;
}

/* -->> */

/* LAYOUT TOP BAR */
.layout-topbar {
  background-color: #22314A;
  box-sizing: border-box;
  display: block;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 778;
}

.layout-topbar.nologin {
  height: 50px;
}

.layout-topbar.nologin {
  height: 100px;
}

.layout-topbar.nologin {
  height: 130px;
}

.layout-topbar.nologin:not(.page-home) {
  position: unset;
}

.layout-topbar.nologin .logo {
  margin-left: 8px;
}

.layout-topbar .menu-button {
  color: #ffffff;
  font-size: 24px;
  position: absolute;
  left: 3px;
  top: 0;
  width: 40px;
  height: 40px;
  line-height: 58px;
  text-align: center;
  transition: background-color .2s;
}

.layout-topbar .menu-button:hover {
  text-decoration: none;
}

.layout-topbar .menu-button i {
  line-height: inherit;
}

.layout-topbar .logo {
  margin-left: 40px;
  margin-top: 5px;
  display: inline-block;
}

.layout-topbar.nologin .logo {
  margin-left: 8px;
}

.layout-topbar .logo img {
  height: 3.9rem;
}

.layout-topbar div.app-context {
  float: right;
  padding-left: 2px;
  vertical-align: middle;
}

.layout-topbar div.app-userinfo {
  float: left;
  padding-left: -15px;
  margin-right: 5px;
}

.layout-topbar div.app-messages {
  float: right;
  vertical-align: middle;
}

.layout-topbar div.app-login,
.layout-topbar div.app-logoff {
  float: left;
}

.layout-topbar div.app-login button,
.layout-topbar div.app-logoff button {
  font-size: 10px;
}

.layout-topbar .app-user {
  font-size: 1.0rem !important;
}

.layout-topbar a {
  color: #ffffff;
}

.layout-topbar a:hover {
  color: #deeafe;
}

.layout-topbar a:focus {
  color: #bed4fe;
}

/* -->> */

/* CSS checkbox (just label) */
body .p-chkbox-label {
  margin: 0 0 0 0.5em;
}

/*    !* CSS buttons *!*/

.p-button:enabled:active, .p-button:enabled:focus {
  background: #22314a;
}

.p-button {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}

.p-button:not(:has(.p-button-label)) {
  padding: 0.5rem 1rem;
  border-radius: 50%;
}

.p-splitbutton-menubutton.p-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: 0 none;
}

.p-splitbutton .p-splitbutton-defaultbutton, .p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-button.p-button-icon-only:not(.p-inputnumber-button) {
  width: 2.357rem;
  height: 2.357rem !important;
}

.p-button.p-button-icon-only.p-inputnumber-button {
  width: 1.5rem;
  height: 1rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  padding: 0.5rem 1rem;
  width: 2.357rem;
  min-width: 2.357rem;
  height: 2.357rem !important;
}

button.p-button:not(.p-autocomplete-dropdown):not(.p-splitbutton-menubutton):not(.p-splitbutton.app-user),
.p-splitbutton {
  margin-right: 0.2em !important;
}

.p-tieredmenu .p-menuitem-link {
  text-decoration: none;
}

button.p-button.p-splitbutton-menubutton {
  margin-left: -0.2rem !important;
}

button.p-button.p-autocomplete-dropdown {
  padding: unset;
}

/* CSS buttons */

.p-button.p-button-lg {
  font-size: 1.25rem;
}

.p-button.p-button-xs {
  font-size: 0.75rem;
  padding: 0.35rem 0.75rem;
}

.p-button.p-button-icon-only.p-button-xs {
  width: 2rem;
  height: 2rem;
}

.p-button.p-button-sm {
  font-size: 0.75rem;
  padding: 0.4375rem 0.875rem;
}

/* WEBUI-POPOVER */
.webui-popover {
  z-index: 10100 !important;
}

.webui-popover-title, .webui-popover-title span {
  font-size: 14px !important;
  text-transform: uppercase;
}

.webui-popover-title span.error {
  color: darkred;
}

div.webui-popover-content {
  font-size: 14px !important;
}

div.webui-popover-content div.error-content {
  padding-left: -40px !important;
  text-align: left;
}

div.webui-popover-content div.error-content ul.list {
  width: 400px !important;
  max-width: 400px !important;
}

div.webui-popover-content div.error-content ul.list li.item {
  word-break: normal !important;
  font-weight: bold !important;
}

div.webui-popover-content div.error-content ul.list li.item span.type {
  font-size: 1rem !important;
  text-transform: uppercase !important;
  color: darkred !important;
  font-weight: bold !important;
}

div.webui-popover-content div.content {
  padding-left: -40px !important;
  text-align: left;
}

div.webui-popover-content div.content ul.list {
  width: 400px !important;
  max-width: 400px !important;
}

div.webui-popover-content div.content ul.list li.item {
  word-break: break-word !important;
}

div.webui-popover-content div.content ul.list li.item span.type {
  font-size: 1rem !important;
  text-transform: uppercase !important;
}

.report-errors {
  border-top: 2px solid #ce8483;
  border-bottom: 2px solid #ce8483;
}

th.report-status, td.report-status {
  background-color: #f2dede;
}

/*  */
.dm-tblrow {
  min-height: 34px;
  height: 34px;
}

.sub-paragraph_1 {
  padding-left: 10px !important;
}

.sub-paragraph_2 {
  padding-left: 20px !important;
}

.sub-paragraph_3 {
  padding-left: 30px !important;
}

.td_unln {
  border-color: black;
  border-style: solid;
  border-width: 0 0 1px 0;
}

/* Rotatable Class */
.rotatable {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* CGray class */
.c_gray {
  background-color: #e0e6e4;
}

.form-input-error {
  color: #b23110;
  font-weight: 800;
  font-size: 0.75rem;
  text-transform: uppercase;
  border-radius: 0 0 2px 2px;
  line-height: 1;
  padding: 0.5rem;
  margin-top: -1rem;
}

.form-input-errors {
  border: 1px solid #B52D30;
}

.form-input-no-errors {
  border: 1px solid green;
}

.form-control,
.p-inputtext,
.p-dropdown,
.p-multiselect {
  border-radius: 10px;
}

.p-multiselect .p-multiselect-label {
  padding: 0.25rem 0.5rem !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0 0.5rem;
  border-radius: 10px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 0.5rem;
}

.layout-content app-start .row:not(.form-group) > [class^="col-"],
.layout-content project-summary .row:not(.form-group) > [class^="col-"] {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dm-document .col-1,
.dm-document .col-2,
.dm-document .col-3,
.dm-document .col-4,
.dm-document .col-5,
.dm-document .col-6,
.dm-document .col-7,
.dm-document .col-8,
.dm-document .col-9,
.dm-document .col-10,
.dm-document .col-11,
.dm-document .col-12 {
  padding: 0.1rem;
}

.topbar > .container {
  position: relative;
}

@media screen and (max-width: 64em) {
  .layout-mask {
    display: block;
  }

  .layout-topbar {
    text-align: center;
  }

  .topbar-menu > li.topbar-menu-themes > ul {
    text-align: left;
  }
}

p-checkbox > label,
p-radiobutton > label {
  margin-bottom: unset;
}

.form-control-local {
  position: relative;
  flex: 1 1 auto;
  width: 1% !important;
  min-width: 0;
  margin-bottom: 0;
}

/* <<-- Magic Radio -->>
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops */
@media (min-width: 1281px) {
  .magic-radio + label:before,
  .magic-checkbox + label:before {
    width: 24px !important;
    height: 24px !important;
  }

  .magic-radio + label:after {
    top: 8px !important;
    left: 8px !important;
    /*width: 8px;*/
    /*height: 8px;*/
  }

  .magic-checkbox + label:after {
    top: 6px !important;
    left: 9px !important;
  }

  .magic-radio + label,
  .magic-checkbox + label {
    padding-left: 28px !important;
    padding-top: 2px !important;
    /*vertical-align: middle;*/
  }
}

table.document-form {
  border-top: 2px solid #8bc0f3;
  border-bottom: 2px solid #8bc0f3;
}

table.document-group {
  margin-top: 3px;
  margin-bottom: 3px;
  /*border-top: 2px solid #9ac8f5 !important;*/
  /*border-bottom: 2px solid #9ac8f5 !important;*/
}

table.document-group tr:not(.document-group-header) {
  border-bottom: 1px solid #d8eafb;
  border-top: 1px solid #d8eafb;
  border-left-color: #F1F3F5;
  border-right-color: #F1F3F5;
}

table.document-group tr.document-group-header {
  border: 1px solid #D2D7DE !important;
  background-color: #F1F3F5;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

div.field-group.field-group-border-top, div.field-label.field-group-border-top {
  border-top: 1px solid #e5e5e5; /* #f1f8ff */
}

div.field-group.field-group-border-bottom, div.field-label.field-group-border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

div.step-content div.field-group,
div.dm-document div.field-group {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin: 0;
}

div.step-content div.field-label,
div.dm-document div.field-label {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin: 0;
}

div.field-value {
  margin-top: 0.5rem;
  text-align: left;
}

.dm-wizard-tab li.nav-item {
  display: block;
}

.dm-wizard-tab li.nav-item.hidden {
  display: none;
}

ul.dm-wizard-tab.step-anchor > li.nav-item.clickable > a {
  color: #000;
}

.docelement-grid {
  border-right: 1px dashed #e5e5e5;
  border-left: 1px dashed #e5e5e5;
  border-top: 1px dashed #e5e5e5;
  border-bottom: 1px dashed #e5e5e5;
}

.docelement-grid-header {
}

.docelement-grid-row {
  border-top: 1px dashed #f6f6f6;
  border-bottom: 1px dashed #f6f6f6;
}

.grid-value {
  /*text-align: center !important;*/
  text-align: left !important;
}

.grid-value-full {
  width: 99%;
}

span.output-status.error-high-priority {
  font-size: 1.3rem;
  color: #ef0c0a !important;
  text-transform: uppercase;
}

span.output-status.error-low-priority {
  font-size: 1.3rem;
  color: #ff8908 !important;
  text-transform: uppercase;
}

span.output-status.error-ok {
  font-size: 1.3rem;
  color: #7dc95b !important;
  text-transform: uppercase;
}

tr.docelement-grid-row-header {
  background-color: #e5e5e5 !important;
  font-weight: bold;
}

tr.docelement-grid-row-summary {
  background-color: #eee !important;
}

tr.docelement-grid-row-level-1 {
  background-color: #d3e3ef !important;
}

tr.docelement-grid-row-level-2 {
  background-color: #d1ebc9 !important;
}

tr.docelement-grid-row-level-3 {
  background-color: #f5e4e1 !important;
}

div.page-break {
  border-bottom: 1px dotted black;
}

/* For RTA TOOLKIT grid: hide Rationale for score */
.reactor-field-score-1 {
  display: none;
}


.field-required {
  color: #e91224;
  font-weight: bold;
}

div.p-splitbutton.app-user {
  height: 30px;
}

.p-button:focus {
  box-shadow: none;
}

div.p-splitbutton.app-user .p-splitbutton-menubutton {
  display: none;
}

.app-user-icon {
  height: 20px;
  width: 19px;
}

.table-singleline > tbody > tr > td {
  white-space: nowrap !important;
  overflow: hidden;
}

tbody > tr.table-row-gray {
  color: rgb(128, 128, 128) !important;
}

tbody > tr.table-row-gray.p-highlight {
  color: white !important;
}

thead.p-treetable-thead tr th {
  font-size: 0.9rem;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-treetable .p-treetable-tbody > tr > td,
.p-treetable .p-treetable-header {
  padding: 0.4rem 0.4rem;
}

.p-datatable .p-datatable-thead > tr > th,
.p-treetable .p-treetable-thead > tr > th {
  padding: 0.4rem 0.4rem;
}

.p-inputtext {
  font-size: unset;
  padding: 0.25rem 0.25rem;
}

.pi {
  font-size: 0.8rem;
}

.form-label-header {
  font-size: 1.1rem;
}

.p-toast.global-message .p-toast-message-content {
  flex-direction: row-reverse;
  word-break: break-all;
}

.p-dialog-header {
  padding: 0.75rem !important;
}

.p-toolbar {
  padding: 0.5rem !important;
}

a.p-tabview-nav-link:hover {
  text-decoration: none;
}

tr.p-row-no-hover:hover {
  background-color: unset !important;
}

.p-fileupload .p-fileupload-row > div {
  padding: 0.5rem 0.5rem;
}

.p-fileupload-row > div {
  width: unset !important;
  word-break: break-all;
}

.p-paginator .p-dropdown {
  height: unset;
}

.p-paginator .p-dropdown-label {
  padding: 0.5rem 0.25rem;
}

.p-dropdown-clear-icon {
  margin-top: -0.3rem !important;
}

.form-check {
  padding-left: 0;
}

.form-check-label {
  padding-left: 0.5rem;
}

.form-control > .p-checkbox-label {
  font-size: 14px;
}

/*bootstrap button fixed (mobile safari)*/
[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: unset;
}

@media (min-width: 1025px) and (max-width: 1280px) {
  public-document-editor .p-toolbar,
  app-dialog-template-preview .p-toolbar,
  app-page-doceditor .p-toolbar,
  app-dialog-doceditor .p-toolbar,
  app-dialog-report-as-form .p-toolbar {
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
}

.w-1-5rem {
  width: 1.5rem;
  min-width: 1.5rem;
}

.w-2rem {
  width: 2rem;
  min-width: 2rem;
}

.w-2-5rem {
  width: 2.5rem;
  min-width: 2.5rem;
}

.w-3rem {
  width: 3rem;
  min-width: 3rem;
}

.w-4rem {
  width: 5rem;
  min-width: 5rem;
}

.w-5rem {
  width: 5rem;
  min-width: 5rem;
}

.w-5-5rem {
  width: 5.5rem;
  min-width: 5.5rem;
}

.w-6rem {
  width: 6rem;
  min-width: 6rem;
}

.w-7rem {
  width: 7rem;
  min-width: 7rem;
}

.w-15rem {
  width: 15rem;
  min-width: 15rem;
}

app-dialog-template-preview .p-dialog-content:not(.uploader),
app-dialog-doceditor .p-dialog-content:not(.uploader) {
  padding: 0 !important;
}

@media (min-width: 1281px) and (max-width: 1799px) {
  public-document-editor .p-toolbar,
  app-dialog-template-preview .p-toolbar,
  app-page-doceditor .p-toolbar,
  app-dialog-doceditor .p-toolbar,
  app-dialog-report-as-form .p-toolbar {
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
}

@media (min-width: 1800px) {
  public-document-editor .p-toolbar,
  app-dialog-template-preview .p-toolbar,
  app-page-doceditor .p-toolbar,
  app-dialog-doceditor .p-toolbar,
  app-dialog-report-as-form .p-toolbar {
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
}

@media (min-width: 1400px) {
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/*todo temporary fix for primeng 12.2*/
.p-datatable-thead {
  display: table-header-group !important;
}

.p-datatable-tbody {
  display: table-row-group !important;
}

.p-datatable-tbody tr, .p-datatable-thead tr {
  display: table-row !important;
}

.p-datatable-tbody tr td, .p-datatable-thead tr th {
  display: table-cell !important;
}

.p-datatable-table {
  border-collapse: separate !important;
  border-spacing: 0;
}

/*temporary fix for primeng 12.2*/

tr.table-header-hidden > th {
  padding: 0 !important;
  margin: 0 !important;;
  height: 0 !important;;
}

.project-label.p-tag-info {
  background-color: #6ba6e7;
  color: #000000;
}

.project-label.p-tag-success {
  background-color: #ffc282;
  color: #000000;
}

.project-label.p-tag-warning {
  background-color: #b2b2b2;
  color: #000000;
}

.project-label.p-tag-danger {
  background-color: #fac6bb;
  color: #000000;
}

tr.rule-grid-error {
  background-color: #F1A7A6FF !important;
}

tr.rule-grid-fail {
  background-color: #FD5E4DFF !important;
}

tr.rule-grid-not-set {
  background-color: #B7CCE2FF !important;
}

tr.rule-grid-ok {
  background-color: #FFFFFF !important;
}

tr.rule-grid-recalc {
  background-color: #8ff18c !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: unset;
}

.overview-box-count-load {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
