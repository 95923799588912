@keyframes hover-color {
  from {
    border-color: #c0c0c0;
  }
  to {
    border-color: #22314A;
  }
}

.magic-radio,
.magic-checkbox {
  position: absolute;
  display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled] {
  cursor: not-allowed;
}

.magic-radio + label,
.magic-checkbox + label {
  position: relative;
  display: block;
  padding-left: 23px;
  cursor: pointer;
  vertical-align: middle;
}

.magic-radio + label:hover:before,
.magic-checkbox + label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}

.magic-radio + label:before,
.magic-checkbox + label:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  border: 1px solid #000000de;
}

[error].dm-field .magic-radio + label:before,
[error].dm-field .magic-checkbox + label:before {
  border: 1px solid #b00020 !important;
}


.magic-radio + label:after,
.magic-checkbox + label:after {
  position: absolute;
  display: none;
  content: '';
}

.magic-radio[disabled] + label,
.magic-checkbox[disabled] + label {
  cursor: not-allowed;
  color: #777777;
}

.magic-radio[disabled] + label:hover, .magic-radio[disabled] + label:before, .magic-radio[disabled] + label:after,
.magic-checkbox[disabled] + label:hover,
.magic-checkbox[disabled] + label:before,
.magic-checkbox[disabled] + label:after {
  cursor: not-allowed;
}

.magic-radio[disabled] + label:hover:before,
.magic-checkbox[disabled] + label:hover:before {
  animation-name: none;
}

.magic-radio[disabled] + label:before,
.magic-checkbox[disabled] + label:before {
  border: 1px solid #22314A;
  background-color: #f8f8f8;
}

.magic-radio:checked + label:before,
.magic-checkbox:checked + label:before {
  animation-name: none;
}

.magic-radio:checked + label:after,
.magic-checkbox:checked + label:after {
  display: block;
}

.magic-radio + label:before {
  border-radius: 50%;
}

.magic-radio + label:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #22314A;
}

.magic-radio:checked + label:before {
  border: 1px solid #22314A;
}

.magic-radio:checked[disabled] + label:before {
  border: 1px solid #f8f8f8;
}

.magic-radio:checked[disabled] + label:after {
  background: #f8f8f8;
}

.magic-checkbox + label:before {
  border-radius: 3px;
}

.magic-checkbox + label:after {
  top: 2px;
  left: 7px;
  box-sizing: border-box;
  width: 6px;
  height: 12px;
  transform: rotate(45deg);
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}

.magic-checkbox:checked + label:before {
  border: #22314A;
  background: #22314A;
}

.magic-checkbox:checked[disabled] + label:before {
  border: #f8f8f8;
  background: #f8f8f8;
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .magic-radio + label,
  .magic-checkbox + label {
    padding-left: 28px;
  }

  .magic-radio + label:before,
  .magic-checkbox + label:before {
    width: 24px;
    height: 24px;
  }

  .magic-radio + label:after {
    top: 8px;
    left: 8px;
  }

  .magic-checkbox + label:after {
    top: 4px;
    left: 9px;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .magic-radio + label,
  .magic-checkbox + label {
    padding-left: 28px;
  }

  .magic-radio + label:before,
  .magic-checkbox + label:before {
    width: 24px;
    height: 24px;
  }

  .magic-radio + label:after {
    top: 8px;
    left: 8px;
  }

  .magic-checkbox + label:after {
    top: 4px;
    left: 9px;
  }
}

.custom-range-wrapper-global {
  display: flex;
}

.custom-range-wrapper {
  position: relative;
  width: 100%;
}

.custom-range {
  /*width: calc(100% - 50px) !important;*/
  /*padding-top: 10px;*/
  /*margin-left: -5px;*/

}

/*input[type="range"]::-webkit-slider-thumb {*/
/*  -webkit-appearance: none;*/
/*  height: 20px;*/
/*  width: 20px;*/
/*  border-radius: 50%;*/
/*  background: #ff4500;*/
/*  cursor: ew-resize;*/
/*  box-shadow: 0 0 2px 0 #555;*/
/*  transition: background .3s ease-in-out;*/
/*}*/

.custom-range-output {
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
}

.custom-range-value span {
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #22314A;
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  margin-top: -30px;
  left: 50%;
  border-radius: 6px;
}

.custom-range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #22314A;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

.orderlist-header, .richtextearea-header {
  background: #f8f9fa;
}

.orderlist-header-left, .richtextearea-header-left {
  display: inline-block;
}

.orderlist-header-right, .richtextearea-header-right {
  display: inline;
  float: right;
  cursor: pointer;
}

.orderlist-boxed, .richtextearea-boxed {
  border: 1px solid #22314A;
  border-radius: 5px;
  margin-top: 0.25rem;
  padding: 0.25rem;
  line-height: 2rem
}

.richtextearea-boxed {
  overflow: auto;
}

.custom-order-list-item {
  color: #ffffff;
  background: #22314A;
  padding: 0.25rem 0.25rem;
  margin: 0;
  border: 1px solid #22314A;
  border-radius: 3rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.custom-order-list-item > .custom-order-list-item-icon-pre {
  padding-right: 0.25rem;
}

.custom-order-list-item > .custom-order-list-item-icon-post {
  padding-left: 0.25rem;
  cursor: pointer;
}

.dropdown-item.active {
  background-color: #22314A;
}

select.dm-field > option:hover {
  background-color: #22314A !important;
}
